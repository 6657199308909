@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');

.clickable {
  cursor: pointer;
}

body {
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
  font-size: 16px;
  color: var(--text-gray);
}

.small {
  font-size: 1em;
    padding: 15px;
}

:root {
  --blue: #2199E8;
  --blue-hover: #0084B2;
  --light-gray: #F5F5F5;
  --gray: #E6E6E6;
  --gray-alt: #E9E9E9;
  --mid-gray: #9AA2A6;
  --dark-gray: #6a7175;
  --text-gray: #575756;
  --button-alt-gray: #CCD0D2;
  --white: #FFFFFF;
  --transparent-white: rgba(255, 255, 255, 0.95);
  --lower-box-height: 320px;
  --equipment-box-height: 200px;
  --equipment-box-width: 200px;
  --contentWidth: 80%;
  --button-height: 40px;
}

@media only screen and (min-width: 1462px) { /* 1170px / 0.8 */
  :root {
    --contentWidth: 1170px;
  }
}

@media only screen and (max-width: 1200px) { /* 1170px / 0.8 */
  .show-only-big-screen {
    display: none;
  }
}

@media only screen and (max-width: 990px) {
  :root {
    --contentWidth: calc(100% - 50px);
  }
}

@media only screen and (max-width: 810px) {
  :root {
    --contentWidth: calc(100%);
    --lower-box-height: 160px;
  }
}

@media only screen and (max-width: 810px) {
  .large-show {
    display: none !important;
  }
}

@media only screen and (min-width: 810px) {
  .small-show {
    display: none !important;
  }
}

.svg-button-container {
  stroke: var(--blue);
  fill: var(--blue);
}

.svg-button-container:hover {
  fill: var(--blue-hover);
  stroke: var(--blue-hover);
}

.semi-bold {
  font-weight: 600;
}

.text-huge {
  font-size: 1.5em;
}

.button {
  cursor: pointer;
  pointer-events: all;
  flex: 0 1 210px;
  height: var(--button-height);
}

.button.button-prim {
  background-color: var(--blue);
  border: solid 1px var(--blue);
  color: var(--white);
  transition: all ease-out 0.25s;
}

.button.button-prim:disabled {
  cursor: not-allowed;
  background-color: var(--mid-gray);
}

.button.button-prim:hover {
  background-color: var(--white);
  color: var(--blue);
  border-style: solid;
  border-color: var(--blue);
  border-width: 1px;
}

.button.button-alt {
  background-color: var(--mid-gray);
  color: var(--white);
  border: none;
  transition: background-color .25s ease-out,color .25s ease-out;
}

.button.button-alt:hover {
  background-color: var(--dark-gray);
}

.button.button-language {
  background-color: var(--button-alt-gray);
  flex: 0 0 var(--button-height);
  min-width: unset;
}

.preformatted-text {
  font-family: inherit;
  margin-bottom: unset;
  white-space: pre-wrap;
}

.check-wheelchair-modal > .modal-dialog > .modal-content {
  border-radius: 0;
}
